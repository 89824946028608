import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import Head from '../components/head'

const IndexPage = () => {
  return(
    <Layout>
    <Head title='Home'/>
      <h1>NFTY CATS</h1>
      <h2>NFTy Cats coming out May 2021!</h2>
      <p>full stacks on stacks coming soon</p>
      <p>More about us? <Link to ="/aboutme">About</Link></p>
    </Layout>
  )
}

export default IndexPage